import { Button, Center, Heading } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Layout from '../layouts/DefaultLayout';

function NotFound() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Center flexDirection="column" p={8}>
        <Heading as="h3" fontSize="xl" my={8}>
          {t('Page not exist')}
        </Heading>
        <Button as="a" colorScheme="brand" href="/">
          {t('Go back home')}
        </Button>
      </Center>
    </Layout>
  );
}

export default NotFound;
